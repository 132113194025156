// @flow

import { type Dispatch, type GetState } from 'redux';
import { index, slice } from 'fp-ts/lib/Array';
import get from 'lodash.get';
import { dispatchWithoutSave } from '../../helpers/actions';
import { flatMap } from '../../helpers/arrays';
import { any } from '../../helpers/conditionals';
import { optionFromEmpty, prop, optionGet } from '../../helpers/functions';
import has from '../../helpers/has';
import { generateInitCropData } from '../../helpers/crop';
import {
  editableTextLayers,
  designAttributeNameByCategory,
} from '../../helpers/layers';
import match from '../../helpers/match';
import { stripHiddenPages } from '../../helpers/pages';
import {
  attributesToArray,
  qtyFromAttributesArray,
} from '../../helpers/product';
import {
  restrictionsTriggeredByChange,
  updateAttributesWithRestrictions,
} from '../../helpers/restrictions';
import {
  getApplicableErrors,
  getInvalidUserPhotoLayerPaths,
} from '../../helpers/schemas';
import { getTemplate } from '../../helpers/templates';
import { convertToSpaces, getDefaultAttributes, disableAttributesIfRestricted } from '../../helpers/urlParameters';
import validator from '../../schemas/validator';
import {
  requestMagentoChildProduct,
  requestQuoteItemUpdate,
  convertChildProductResponse, magentoResponseIsOOS, ERROR_NAME_OOS, isProductOptionOOSMessage,
} from '../../services/magento';
import photo from '../../types/photo';
import {
  forceSaveProject,
  manualSave,
  saveProjectBeforeAddToCart,
} from '../project/actions';
import {
  applyCropDataToLayer,
  applyPageCount,
  applyTemplateToPages,
  removeUserPhotos,
  validateResolution,
  insertPages,
  removePages, applyPhotoToLayer, applyDesignToPage, setLayerData,
} from '../project/pages/actions';
import { FAILURE } from '../project/saveState/constants';
import { setTemplate } from '../template/actions';
import {
  confirmCropReset,
  confirmPageRemoval,
  setCurrentPage,
  setFontsLoaded,
} from '../ui/actions';
import { showLoginForm } from '../userAuthentication/actions';
import {
  getAllPhotos,
  getFailedPhotos,
  getNonFailedUploadablePhotos,
} from '../userPhotos/selectors';
import {
  productAttributesSelector,
  productRestrictionsSelector,
  productCategorySelector,
} from './selectors';

import {
  SCHEMA_ERROR_DEFAULT_TEXT,
  SCHEMA_ERROR_EMPTY_PAGE,
  SCHEMA_ERROR_EMPTY_TEXT,
} from '../../schemas/errors';

import {
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_FAILURE_MISMATCHED_USER,
  ADD_TO_CART_FAILURE_OUT_OF_STOCK,
  ADD_TO_CART_PROCESSING,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  CHILD_PRODUCT_REQUESTING_CART_STATE,
  CHILD_REQUEST_STATUS_FAILURE,
  CHILD_REQUEST_STATUS_IN_FLIGHT,
  CHILD_REQUEST_STATUS_SUCCESS,
  CONFIRMING_DEFAULT_TEXT_CART_STATE,
  CONFIRMING_EMPTY_CART_STATE,
  CONFIRMING_EMPTY_TEXT_CART_STATE,
  CONFIRMING_UNUSED_FAILED_PHOTOS_CART_STATE,
  CONFIRMING_MISSING_ADDRESSING,
  CUSTOMER_ACK_EMPTY_PAGE,
  DEFAULT_TEXT_LAYERS_VALIDATION,
  DO_NOT_ADD_TO_CART,
  EMPTY_PAGES_VALIDATION,
  EMPTY_TEXT_VALIDATION,
  FAILED_PROJECT_SERVICE_SAVE_VALIDATION,
  UNUSED_FAILED_PHOTOS_VALIDATION,
  FINAL_SAVE_FAILED_CART_STATE,
  FINAL_SAVE_IN_PROGRESS_CART_STATE,
  PROCESSING_CART_STATE,
  UPDATE_ATTRIBUTE,
  UPDATE_MAGENTO_DATA,
  UPDATE_PRODUCT_CHILD_REQUEST_STATUS,
  UPLOADS_IN_FLIGHT_CART_STATE,
  ADD_TO_CART_FAILURE_USED_PHOTO_FAILURES,
  PRODUCT_UPDATE_PAGE_COUNT,
  ADD_TO_CART_ADDED_TO_CART,
  UPDATE_QTY,
  CONFIRMING_MISSING_MANUAL_ADDRESS,
  REMOVE_ATTRIBUTE,
  ADD_TO_CART_MAX_LIMIT_REACHED,
  UPDATE_SHOULD_ADD_TO_CART_AND_START_NEW_PROJECT,
  ADD_TO_CART_AND_OPEN_NEW_DEFAULT_PROJECT_CART_STATE,
} from './constants';

import {
  currentVisiblePageCountMinusCoverSelector,
  projectInCartSelector,
  userPhotoIdsInProjectSelector,
  projectPagesSelector,
  getLayersWithDefaultText,
} from '../project/pages/selectors';

import {
  FORCE_PROJECT_SAVE_FAILURE,
  FORCE_PROJECT_SAVE_SUCCESS,
  PROJECT_STATE_IN_CART,
  PROJECT_STATE_UPDATED_IN_CART,
} from '../project/constants';
import {
  envelopeAddressingValueSelector,
  returnAddressSelector,
  csvKeySelector,
} from '../envelopeAddressing/selectors';
import { USER_PHOTO } from '../../constants/layers';
import {
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2,
} from '../../constants/envelopes'

import {
  sendAnalyticsForAttributeChange,
  sendAnalyticsForCartWarning,
  itlyProductAddToCartEvent,
} from '../analytics/actions';
import { MANUAL_RECIPIENT_ADDRESS } from '../envelopeAddressing/constants';
import { validateManualAddress } from '../envelopeAddressing/actions';
import { CATEGORIES_PAGE_COUNT_MATCH_QUANTITY } from '../../constants/products';
import { getPhotoFromLayer } from '../../helpers/images';
import { NR_PLACEHOLDER_TEXT_WARNING } from '../../helpers/newrelic';
import { getAUAddToCartUrl } from '../../helpers/urls';
import { getFoilColor } from '../../helpers/attributes';

const isUserPhoto = x => x.type === 'user_photo';
const doNotSave = false;

const initCropDataForAll = (state: Object) =>
  flatMap(state.project.pages, (page) =>
    page.layers
      .filter(
        (layer) =>
          layer.type === USER_PHOTO &&
          has(layer)('data') &&
          has(layer.data)('userPhotoId')
      )
      .map((layer) => ({
        layerId: layer.id,
        cropData: getPhotoFromLayer(state.userPhotos)(layer)
          .map((p) => {
            const extractedPhoto = photo.extract(p)
            return generateInitCropData(extractedPhoto, layer, page.surface);
          })
          .getOrElseValue({}),
        pageId: page.id,
      }))
  );

export const setChildRequestStatus = (childRequestStatus: string) => ({
  type: UPDATE_PRODUCT_CHILD_REQUEST_STATUS,
  payload: {
    status: childRequestStatus,
  },
});

export const setMagentoProductData = (
  productData: Object,
  changedAttributeKeys: Array<string>
) => ({
  type: UPDATE_MAGENTO_DATA,
  payload: {
    ...productData,
    changedAttributeKeys,
  },
});

export const productUpdatePageCount = (pageCount: number) => ({
  type: PRODUCT_UPDATE_PAGE_COUNT,
  payload: {
    pageCount,
  },
});

function addToCartProcessing() {
  return {
    type: ADD_TO_CART_PROCESSING,
    payload: {
      cartState: PROCESSING_CART_STATE,
    },
  };
}

export function customerAckEmptyPage() {
  return {
    type: CUSTOMER_ACK_EMPTY_PAGE,
  };
}

export const addToCartConfirmingDefaultText = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: CONFIRMING_DEFAULT_TEXT_CART_STATE,
  },
});

export const addToCartAndOpenNewDefaultProject = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: ADD_TO_CART_AND_OPEN_NEW_DEFAULT_PROJECT_CART_STATE,
  },
});

export const addToCartConfirmingUnusedFailedPhotos = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: CONFIRMING_UNUSED_FAILED_PHOTOS_CART_STATE,
  },
});

export const saveProjectBeforeAddToCartFailed = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: FINAL_SAVE_FAILED_CART_STATE,
  },
});

export const addToCartConfirmingEmptyText = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: CONFIRMING_EMPTY_TEXT_CART_STATE,
  },
});

export const addToCartFinalSaveInProgress = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: FINAL_SAVE_IN_PROGRESS_CART_STATE,
  },
});

export const addToCartConfirmingEmptyPages = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: CONFIRMING_EMPTY_CART_STATE,
  },
});

export const addToCartConfirmingMissingAddressing = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: CONFIRMING_MISSING_ADDRESSING,
  },
});

export const addToCartConfirmingMissingManualAddress = (skipCheck: ?string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    skipCheck,
    cartState: CONFIRMING_MISSING_MANUAL_ADDRESS,
  },
});

export function addToCartUploadsInFlight() {
  return {
    type: ADD_TO_CART_PROCESSING,
    payload: {
      cartState: UPLOADS_IN_FLIGHT_CART_STATE,
    },
  };
}

export function addToCartChildProductRequesting() {
  return {
    type: ADD_TO_CART_PROCESSING,
    payload: {
      cartState: CHILD_PRODUCT_REQUESTING_CART_STATE,
    },
  };
}

const addToCartUpdatedInCart = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: PROJECT_STATE_UPDATED_IN_CART,
  },
});

const addToCartAddedToCart = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: ADD_TO_CART_ADDED_TO_CART,
  },
});

export const addToCartOutOfStock = (message: string) => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: ADD_TO_CART_FAILURE_OUT_OF_STOCK,
    cartStateMessage: message,
  },
});

export const addToCartMaxQuantityReached = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: ADD_TO_CART_MAX_LIMIT_REACHED,
  },
});

export const addToCartDoNotAdd = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: DO_NOT_ADD_TO_CART,
  },
});

// Generic add-to-cart failure
export const addToCartFailure = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: ADD_TO_CART_FAILURE,
  },
});

// Add to cart failure for when Magento user and flash user don't match anymore.
export const addToCartFailureMismatchedUser = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: ADD_TO_CART_FAILURE_MISMATCHED_USER,
  },
});

// Add to cart failure for when used Photos have failed to upload.
export const addToCartFailureUsedPhotoFailures = () => ({
  type: ADD_TO_CART_PROCESSING,
  payload: {
    cartState: ADD_TO_CART_FAILURE_USED_PHOTO_FAILURES,
  },
});

// update the toggle to see if we should open a new window with a default project of the same sku
export const setShouldAddToCartAndOpenNewDefaultProject = (shouldAddToCartAndOpenNewDefaultProject: boolean) => ({
  type: UPDATE_SHOULD_ADD_TO_CART_AND_START_NEW_PROJECT,
  payload: {
    shouldAddToCartAndOpenNewDefaultProject,
  },
});

// Attempts to save the project one last time, and then attempts to add the project to cart.
export const addToCart = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const state = getState();

    const { product, user } = state;

    const { flashId } = user;

    const pageCountMinusCover = currentVisiblePageCountMinusCoverSelector(
      state
    );

    if (state.product?.shouldAddToCartAndOpenNewDefaultProject) {
      dispatch(addToCartAndOpenNewDefaultProject());
    } else {
      dispatch(addToCartProcessing());
    }

    const attributes = attributesToArray(
      product.attributes,
      pageCountMinusCover,
      product.category
    );

    const saveResponse = await forceSaveProject(state);

    if (saveResponse.ok) {
      // If the save is successful, add the project to the cart.
      const body = {
        options: attributes,
        cartItem: {
          qty: state.product.qty || qtyFromAttributesArray(attributes),
          sku: convertToSpaces(product.sku),
          extension_attributes: {
            au_project_id: state.project.id,
            au_project_version: "1"
          },
        },
      };

      const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
        body: JSON.stringify(body),
      };

      dispatch({ type: ADD_TO_CART_REQUEST });

      const addToCartResponse = await fetch(getAUAddToCartUrl(), options);
      const addToCartJson = await addToCartResponse.json();

      await match(
        500,
        () => {
          if (
            has(addToCartJson)('message') &&
            addToCartJson.message.includes('MISMATCHED_USER_ERROR') &&
            !addToCartJson.message.includes(flashId)
          ) {
            // If there is a mismatched user error from Magento, show the mismatched user modal.
            dispatch(addToCartFailureMismatchedUser());
          } else {
            // Generic 500 error....
            dispatch(addToCartFailure());
          }
        },
        400,
        () => {
          if (magentoResponseIsOOS(addToCartJson)) {
            let message = addToCartJson.message

            if (isProductOptionOOSMessage(message)) {
              const oosProductOption = addToCartJson.message.split(' - ')[1]
              message = `"${oosProductOption}" is currently out of stock. Head to the Customize Tab to select a different option.`
            }

            dispatch(addToCartOutOfStock(message));
          } else if (
            has(addToCartJson)('message') && addToCartJson.message === "Oops, you can't buy that"
          ) {
            dispatch(addToCartMaxQuantityReached());
          } else {
            // Generic 400 error....
            dispatch(addToCartFailure());
          }
        },
        401,
        () => {
          // If the user is logged out...
          dispatch(addToCartFailure());

          // Show the login form, adding to cart automatically after login.
          dispatch(showLoginForm(true));
        },
        200,
        async () => {
          try {
            await dispatch(itlyProductAddToCartEvent());
          } catch (e) {
            console.log(e);
            window.newrelic.noticeError(e);
          }

          dispatch({ type: ADD_TO_CART_SUCCESS });

          if (state.product?.shouldAddToCartAndOpenNewDefaultProject) {
            dispatch(setShouldAddToCartAndOpenNewDefaultProject(false));
            window.location.href = `${process.env.REACT_APP_PUBLIC_URL || ''}/?sku=${product.sku}`;
          } else {
            dispatch(addToCartAddedToCart());
          }
        },
        match.default,
        () => {
          // All other errors...
          dispatch(addToCartFailure());
        }
      )(addToCartResponse.status);
    } else {
      // If the save was unsuccessful, update the cartState so that the save failure dialog is shown.
      dispatch({ type: FORCE_PROJECT_SAVE_FAILURE });
    }
  } catch (e) {
    window.newrelic.noticeError(e);
    dispatch(addToCartFailure());
  }
};

export const isRenderedContentMissing = (state: Object) =>
  prop('project')(state)
    .chain(prop('pages'))
    .map(ps =>
      ps.filter(p =>
        prop('layers')(p)
          .map(ls => editableTextLayers(ls))
          .chain(optionFromEmpty)
          .map(ls =>
            ls.filter(
              l =>
                !(
                  has(l)('data') &&
                  has(l.data)('renderedContent') &&
                  l.data.renderedContent !== null
                )
            )
          )
          .map(ls => ls.length > 0)
          .getOrElseValue(false)
      )
    )
    .map(missingContentLayers => missingContentLayers.length > 0)
    .getOrElseValue(false);

export const validateProject = (dispatch: Dispatch) => (getState: GetState) => (
  skipCheck: ?string
) => (
  success: () => (
    dispatch: Dispatch,
    getState: GetState
  ) => void | Promise<void>
) => {
  dispatch(addToCartProcessing());

  const state = getState();
  const cartState = state.project.lockState;

  // handling the case where the project service is down and the latest
  // changes aren't saved
  if (
    cartState !== PROJECT_STATE_IN_CART &&
    state.project.saveState.status === FAILURE
  ) {
    if (skipCheck !== FAILED_PROJECT_SERVICE_SAVE_VALIDATION) {
      dispatch(addToCartFinalSaveInProgress());
      return dispatch(saveProjectBeforeAddToCart());
    }

    return dispatch(saveProjectBeforeAddToCartFailed());
  }

  const invalidUserPhotoPaths = getInvalidUserPhotoLayerPaths(state.userPhotos)(
    state.project.pages
  );

  if (invalidUserPhotoPaths.length > 0) {
    dispatch(removeUserPhotos(invalidUserPhotoPaths));

    window.newrelic.addPageAction('removedInvalidUserPhotoIds', {
      projectId: state.project.id,
      userId: state.user.flashId,
      invalidUserPhotoPaths,
    });
  }

  const stateWithoutInvalidPhotos = getState();

  validator.validate('singlePhotoProject', stateWithoutInvalidPhotos);

  const applicableErrors = getApplicableErrors(validator.errors)(
    stateWithoutInvalidPhotos.project.pages
  );

  const { skippedChecks } = stateWithoutInvalidPhotos.product;

  const checksToSkip = [...skippedChecks, skipCheck];

  const failedPhotos = getFailedPhotos(stateWithoutInvalidPhotos);
  const usedPhotoIds = userPhotoIdsInProjectSelector(stateWithoutInvalidPhotos);

  // Array of Photos that are used in the project and have failed to upload.
  const usedFailedPhotos = failedPhotos.filter((p) =>
    usedPhotoIds.includes(photo.getId(p))
  );

  // If there are used photos that failed to upload, fail the add to cart process and notify the user.
  if (usedFailedPhotos.length > 0) {
    return dispatch(addToCartFailureUsedPhotoFailures());
  }

  // If any project validation errors are present...
  if (applicableErrors.length > 0) {
    // If any text layers have been left with their default text, confirm that the user knows that.
    const defaultTextLayers = applicableErrors.filter(
      (error) => error.message === SCHEMA_ERROR_DEFAULT_TEXT
    );
    if (
      defaultTextLayers.length > 0 &&
      !checksToSkip.includes(DEFAULT_TEXT_LAYERS_VALIDATION)
    ) {
      dispatch(
        sendAnalyticsForCartWarning({
          cartConfirmReason: 'text-layers-with-default-text',
        })
      );

      // Alert NR of placeholder text warnings
      const product = state.product;
      const layersWithPlaceholderText = getLayersWithDefaultText(state);
      if (layersWithPlaceholderText.length) {
        const defaultTextContent = layersWithPlaceholderText.map((l) => ({ content: l.data.content, pageId: l.pageId, id: l.id }));
        window.newrelic.addPageAction(NR_PLACEHOLDER_TEXT_WARNING, {
          sku: product.sku,
          category: product.category,
          defaultTextContent: JSON.stringify(defaultTextContent)
        });
      }

      return dispatch(addToCartConfirmingDefaultText(skipCheck));
    }

    // If there are text layers that are supposed to have text, confirm that with the user.
    const emptyTextLayers = applicableErrors.filter(
      error => error.message === SCHEMA_ERROR_EMPTY_TEXT
    );
    if (
      emptyTextLayers.length > 0 &&
      !checksToSkip.includes(EMPTY_TEXT_VALIDATION)
    ) {
      dispatch(
        sendAnalyticsForCartWarning({
          cartConfirmReason: 'empty-text-layers',
        })
      );
      return dispatch(addToCartConfirmingEmptyText(skipCheck));
    }

    // If there are userPhoto layers that are left empty, confirm with the user that they know that.
    const emptyPages = applicableErrors.filter(
      error => error.message === SCHEMA_ERROR_EMPTY_PAGE
    );
    if (
      emptyPages.length > 0 &&
      !checksToSkip.includes(EMPTY_PAGES_VALIDATION)
    ) {
      dispatch(
        sendAnalyticsForCartWarning({
          cartConfirmReason: 'empty-photo-layers',
        }));

      return dispatch(addToCartConfirmingEmptyPages(skipCheck));
    }
  }

  const validateEnvelopeAddressing = (envelopeAddressingType) => {
    const type = envelopeAddressingType.getOrElseValue('').toLowerCase();

    switch (type) {
      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE.toLowerCase():
      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2.toLowerCase():
        return true;

      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH.toLowerCase():
      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2.toLowerCase():
        return (
          returnAddressSelector(stateWithoutInvalidPhotos) &&
          csvKeySelector(stateWithoutInvalidPhotos)
            .map((csvKey) => csvKey && csvKey.length > 0)
            .getOrElseValue(false)
        );

      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY.toLowerCase():
      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2.toLowerCase():
      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2.toLowerCase():
      case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY.toLowerCase():
        return returnAddressSelector(stateWithoutInvalidPhotos);

      default:
        return true;
    }
  };

  const envelopeAddressingType = envelopeAddressingValueSelector(stateWithoutInvalidPhotos);

  // Check that the necessary pieces of state are filled out for the selected kind of envelope addressing.
  const envelopeAddressingValid = validateEnvelopeAddressing(envelopeAddressingType);

  const manualAddressingValid = envelopeAddressingType.map(match(
    MANUAL_RECIPIENT_ADDRESS, () => {
      const address = optionGet('envelopeAddressing.manualRecipientAddress')(stateWithoutInvalidPhotos).getOrElseValue({});
      const errors = validateManualAddress(address);
      return errors.length === 0;
    },
    match.default, () => true
  )).getOrElseValue(true);

  if (!manualAddressingValid) {
    dispatch(sendAnalyticsForCartWarning({
      cartConfirmReason: 'missing-manual-recipient-addressing-data',
    }));
    return dispatch(addToCartConfirmingMissingManualAddress(skipCheck));
  }

  /* If the envelope addressing state is invalid, offer the user the option to either fill out the required
       information or remove envelope addressing. */
  if (!envelopeAddressingValid) {
    dispatch(
      sendAnalyticsForCartWarning({
        cartConfirmReason: 'missing-addressing-data',
      })
    );
    return dispatch(addToCartConfirmingMissingAddressing(skipCheck));
  }

  // If there are *unused* photos that have failed to upload, confirm that the user is okay with that.
  if (
    failedPhotos.length > 0 &&
    !checksToSkip.includes(UNUSED_FAILED_PHOTOS_VALIDATION)
  ) {
    dispatch(
      sendAnalyticsForCartWarning({
        cartConfirmReason: 'unused-photos-failed-to-upload',
      })
    );
    return dispatch(addToCartConfirmingUnusedFailedPhotos(skipCheck));
  }

  /* If there are uploads in flight (i.e. non-failed uploadable Photos are present in state),
      let the user know that we're waiting for those to finish. */
  const nonFailedUploadablePhotos = getNonFailedUploadablePhotos(
    stateWithoutInvalidPhotos
  );
  if (nonFailedUploadablePhotos.length > 0) {
    return dispatch(addToCartUploadsInFlight());
  }

  // If there are no more non-skipped problems, request the child product from Magento.
  if (
    cartState === PROJECT_STATE_IN_CART &&
    getState().product.childRequestStatus === CHILD_REQUEST_STATUS_IN_FLIGHT
  ) {
    return dispatch(addToCartChildProductRequesting());
  }

  // If any editable text layers have not been rendered, render them.
  if (isRenderedContentMissing(getState())) {
    dispatch(setFontsLoaded());
    window.newrelic.addPageAction('cart add missing rendered content', {
      projectId: prop('project')(getState())
        .chain(prop('id'))
        .getOrElseValue('none'),
      flashId: prop('user')(getState())
        .chain(prop('flashId'))
        .getOrElseValue('none'),
    });
  }

  // If we've completed all of the above checks, call the success callback (either adding to cart or updating in cart).
  return dispatch(success());
};

export function afterUpdateValidationCompletes() {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const pageCountMinusCover = currentVisiblePageCountMinusCoverSelector(
      state
    );

    forceSaveProject(state)
      .then(response =>
        response
          .json()
          .then(json => ({ json, response }))
          .catch(() => ({
            json: '',
            response,
          }))
      )
      .catch(() => ({
        // this is here mainly for testing by request blocking in devtools
        json: '',
        response: null,
      }))
      .then(({ response }) => {
        if (
          response == null ||
          !(response.status >= 200 && response.status < 300)
        ) {
          dispatch({ type: FORCE_PROJECT_SAVE_FAILURE });
        } else {
          dispatch({ type: FORCE_PROJECT_SAVE_SUCCESS });

          // Indicate that we're now requesting the product
          dispatch(addToCartChildProductRequesting());
          const newState = getState();
          const attributes = attributesToArray(
            newState.product.attributes,
            pageCountMinusCover,
            newState.product.category
          );
          requestQuoteItemUpdate(
            newState.product.quoteItemId,
            newState.product.quoteId,
            newState.project.id,
            newState.product.attributes,
            newState.product.magento.attributes,
            newState.product.magento.options,
            pageCountMinusCover,
            newState.product.qty || qtyFromAttributesArray(attributes),
            newState.product.sku
          )
            .then(() => {
              dispatch(addToCartUpdatedInCart());
            })
            .catch((e) => {
              if (e.name === ERROR_NAME_OOS) {
                dispatch(addToCartOutOfStock(e.message));
              } else {
                window.newrelic.noticeError(e);
                dispatch(addToCartFailure());
              }
            });
        }
      });
  };
}

export function updateProjectInCart(skipCheck: ?string = '') {
  return (dispatch: Dispatch, getState: GetState) =>
    validateProject(dispatch)(getState)(skipCheck)(
      afterUpdateValidationCompletes
    );
}

/**
 * validateAndAddToCart validates the current state of the project before adding to cart
 * Validations include; invalid photos, default text layers, empty text layers, empty photo layers, uploads in flight
 * @param skipCheck
 * @returns {function(*, *)}
 */
export function validateAndAddToCart(skipCheck: ?string = '', shouldAddToCartAndOpenNewDefaultProject: ?boolean = false) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldAddToCartAndOpenNewDefaultProject) {
      dispatch(setShouldAddToCartAndOpenNewDefaultProject(true));
    }

    return validateProject(dispatch)(getState)(skipCheck)(addToCart);
  };
}

export const setChildProductData = (product: Object, attributes: Object) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch(setChildRequestStatus(CHILD_REQUEST_STATUS_IN_FLIGHT));

  const pageCountMinusCover = currentVisiblePageCountMinusCoverSelector(
    getState()
  );
  return requestMagentoChildProduct(product, pageCountMinusCover)
    .then(convertChildProductResponse)
    .then((json) => {
      dispatch(setChildRequestStatus(CHILD_REQUEST_STATUS_SUCCESS));
      dispatch(setMagentoProductData(json, Object.keys(attributes)));
      if (
        getState().product.cartState === CHILD_PRODUCT_REQUESTING_CART_STATE
      ) {
        if (projectInCartSelector(getState())) {
          dispatch(updateProjectInCart());
        } else {
          dispatch(validateAndAddToCart());
        }
      }
    })
    .catch((e) => {
      dispatch(setChildRequestStatus(CHILD_REQUEST_STATUS_FAILURE));
      window.newrelic.noticeError(e);
    });
};

// Redux action that updates the product's attributes.
export const updateAttributeAction = (attributes: Object): Object => ({
  type: UPDATE_ATTRIBUTE,
  payload: {
    attributes,
  },
});

const shouldHaveFoilCoverNoDefaultState = (updatedProduct, productData): boolean =>
  updatedProduct.attributes.printing_style === 'foil' && getFoilColor(productData) && !updatedProduct.attributes.foil_color;

export function updateAttribute(attributes: Object) {
  return (dispatch: Dispatch, getState: GetState) => {
    const oldSurface = get(getState(), 'template.surface', {});
    dispatchWithoutSave(dispatch, updateAttributeAction(attributes));
    /**
     * Fetch new template.
     * Needs to be done after the UPDATE_ATTRIBUTE action,
     * so that it doesn't take two tries to make a template change stick.
     */
    const product = getState().product;
    const productData = window.productData;
    const template = getTemplate(productData)(product.attributes);

    // This sets the Template Object in state by dumping the RETURN of the updateTemplate()
    dispatchWithoutSave(dispatch, setTemplate(template, doNotSave));

    // set active page to first page
    const currentPage = getState().ui.currentPage;
    dispatch(setCurrentPage(getState().project.pages[0].id));
    const statePrePageCountUpdate = getState();

    // modify the page count
    dispatch(
      applyPageCount(
        template,
        stripHiddenPages(statePrePageCountUpdate.project.pages).length,
        productData.category,
        statePrePageCountUpdate.product.attributes,
        statePrePageCountUpdate.ui.editorFontsLoaded
      )
    );

    const applyToAll = getState().ui.appliedToAllToggle;
    if (applyToAll) {
      const state = getState();
      const layerId = state.project.pages[0].layers[0].id;
      const photoId = state.project.pages[0].layers[0].data.userPhotoId;
      const listPages = projectPagesSelector(state);
      const allPhotos = getAllPhotos(state);
      const constructedPhoto = allPhotos.find(photoObj => photo.extract(photoObj).id === photoId);
      const firstPage = listPages[0];
      const firstPageLayer = firstPage.layers[0];
      const firstPageDesign = firstPage.surface.design;
      listPages.slice(1, listPages.length).forEach((page) => {
        dispatch(applyPhotoToLayer(constructedPhoto, page.id, layerId)).then(() => {
          dispatch(setLayerData(page.id, layerId, firstPageLayer.data));
          dispatch(applyDesignToPage(firstPageDesign, page.id));
        });
      });
    }

    dispatch(
      applyTemplateToPages(
        template,
        statePrePageCountUpdate.ui.editorFontsLoaded,
        statePrePageCountUpdate.product.attributes,
        statePrePageCountUpdate.userPhotos,
        productData.category
      )
    );

    dispatch(validateResolution());

    // If new surface is not the same size as the previous...
    if (
      template &&
      (template.surface.width !== oldSurface.width ||
        template.surface.height !== oldSurface.height)
    ) {
      // reset init crop data for user_photo layers
      const cropDataByLayer = initCropDataForAll(getState());

      cropDataByLayer.forEach(({ cropData, layerId, pageId }) => {
        dispatch(applyCropDataToLayer(cropData, layerId, true, pageId, false));
      });

      dispatch(manualSave());
    }

    // If necessary, update the design attribute.
    const { project: updatedProject } = getState();

    // If the template says this product needs a design attribute...
    if (template && template.needsDesignAttribute) {
      // Get the design attribute value, based off of the first page's design id.
      const designAttributeValue: string = index(0)(updatedProject.pages)
        .chain(optionGet('surface.design'))
        .map(designAttributeNameByCategory(productData.category))
        .getOrElseValue('');

      // Update the design attribute.
      dispatch(
        updateAttributeAction({
          design: designAttributeValue,
        })
      );
    }
    if (getState().project.pages.filter(i => i.id === currentPage).length > 0) {
      dispatch(setCurrentPage(currentPage));
    }

    // FINALLY, update the child product.
    const { product: updatedProduct } = getState();

    // This validation has been added because for `printing_style: foil` we add an additional attribute `foil_color`
    // whose default value is "" upon selection of `printing_style: foil` from `printing_style: digital`
    // and in order for foil layers to properly generate we need to specify a `foil_color`
    if (shouldHaveFoilCoverNoDefaultState(updatedProduct, productData)) {
      updatedProduct.attributes.foil_color = getFoilColor(productData);
    }

    // This validation has been added because for `printing_style: digital` we want to completely remove
    // the attribute `foil_color` rather than just passing "" as its value
    if (updatedProduct.attributes.printing_style === 'digital') {
      delete updatedProduct.attributes.foil_color;
    }

    dispatch(setChildProductData(updatedProduct, updatedProduct.attributes));
  };
}

export const removeAttribute = (attributeKey: string) => ({
  type: REMOVE_ATTRIBUTE,
  payload: {
    attributeToRemove: attributeKey,
  },
});

/**
 * Dispatch attribute update action(s)
 * @param attribute
 * @returns {function(Dispatch, GetState)}
 */
export function preUpdateAttribute(
  attribute: { key: string, value: any },
  undoableItemId,
  sendAnalytics: boolean = false
) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const currentMinPages = await state.template.pages.min;
    const currentSurface = state.template.surface;

    const templateAttributes = productAttributesSelector(state);
    const templateRestrictions = productRestrictionsSelector(state);

    const triggeredRestrictions = restrictionsTriggeredByChange(
      templateRestrictions
    )(attribute.key)(attribute.value);

    let nextAttributes = updateAttributesWithRestrictions(
      state.product.attributes
    )(triggeredRestrictions)(attribute.key)(attribute.value)(
      templateAttributes
    );

    let withAllAttributes = disableAttributesIfRestricted({
      ...getDefaultAttributes(window.productData.attributes)(window.productData.category),
      ...nextAttributes,
    });

    withAllAttributes = updateAttributesWithRestrictions(
      withAllAttributes
    )(triggeredRestrictions)(attribute.key)(attribute.value)(
      templateAttributes
    );

    const allAttributeNames = Object.keys(withAllAttributes);
    const currentAttributeNames = Object.keys(nextAttributes);
    const missingAttributes = allAttributeNames.filter(x => !currentAttributeNames.includes(x));
    const extraAttributes = currentAttributeNames.filter(x => !allAttributeNames.includes(x));

    if (missingAttributes.length) {
      nextAttributes = {
        ...nextAttributes,
        ...withAllAttributes,
      };
    }

    if (extraAttributes.length) {
      // eslint-disable-next-line
      extraAttributes.map(x => {
        delete nextAttributes[x];
        dispatch(removeAttribute(x));
      });
    }

    if (sendAnalytics) {
      dispatch(
        sendAnalyticsForAttributeChange({
          attributeKey: attribute.key,
          attributeValue: attribute.value,
        })
      );
    }

    // this is what the next product will be
    const nextProduct = {
      ...state.product,
      attributes: nextAttributes,
    };

    // this is what the next template will be
    const nextTemplate = getTemplate(window.productData)(
      nextProduct.attributes
    );

    if (nextTemplate) {
      const newMinPages = nextTemplate.pages.min;
      const nextSurface = nextTemplate.surface;

      /* Whether or not any of the pages going to be removed have data in them, or, if the number of pages in the
        updated project will be the same, whether or not any page at all has data. */
      // toBeRemovedPagesHaveData :: Boolean
      const toBeRemovedPagesHaveData = any(
        flatMap(
          state.project.pages.slice(newMinPages - currentMinPages),
          page => page.layers
        ).map(page => 'data' in page && !!page.data.userPhotoId)
      );

      // pagesExistingHaveData :: Boolean
      const pagesExistingHaveData = any(
        flatMap(state.project.pages, page => page.layers).map(
          page => 'data' in page && !!page.data.userPhotoId
        )
      );

      const currLayers = state.template.layers;
      const newLayers = nextTemplate.layers;

      // if the layers are different show edit changes removal warning
      // layersAreDifferent :: Boolean
      const layersAreDifferent = any(
        newLayers
          .filter(isUserPhoto)
          .map(x => currLayers.filter(y => y.id === x.id).length < 1)
      );

      // projectWillHaveMultiplePages :: Boolean
      const projectWillHaveMultiplePages = newMinPages > 1;

      // surfacesDiffer :: Boolean
      const surfacesDiffer =
        nextSurface.height !== currentSurface.height ||
        nextSurface.width !== currentSurface.width;

      if (newMinPages < currentMinPages && toBeRemovedPagesHaveData) {
        /* If the updated number of pages is less than the current number of pages, AND any of the pages that
          would be removed have existing userphotos, confirm with the user that they want to remove pages. */
        dispatch(confirmPageRemoval(nextAttributes));
      } else if (
        (surfacesDiffer || layersAreDifferent) &&
        pagesExistingHaveData &&
        projectWillHaveMultiplePages
      ) {
        /* If the current and updated surfaces differ in size OR any of the layers will be different, AND the pages have existing data */
        dispatch(confirmCropReset(nextAttributes, undoableItemId));
      } else {
        dispatch(updateAttribute(nextAttributes));
      }
    }
  };
}

// Update the quantity
export const updateQuantity = (qty: number) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const pages = projectPagesSelector(getState());

  dispatch({
    type: UPDATE_QTY,
    payload: {
      qty,
    },
  });
  const category = productCategorySelector(getState());

  /**
   * If a product is in a category that should match visible page count to quantity,
   * we need to add/remove pages accordingly on quantity update
   */
  if (CATEGORIES_PAGE_COUNT_MATCH_QUANTITY.includes(category)) {
    if (qty > pages.length) {
      dispatch(insertPages(pages[pages.length - 1].id, qty - pages.length, false));
    } else if (qty < pages.length) {
      dispatch(removePages(slice(qty - 1, pages.length - 1)(pages).map(x => x.id), false));
      // @todo figure out how to pull out items from layer data
    }
  }
  const { product: updatedProduct } = getState();

  dispatch(setChildProductData(updatedProduct, updatedProduct.attributes));
};

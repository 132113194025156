// @flow
import find from 'lodash.find';
import type { GetState, Dispatch } from 'redux';
import { option } from 'fp-ts';
import { index } from 'fp-ts/lib/Array';
import { isSome, type Option } from 'fp-ts/lib/Option';
import type { Page } from '../../../types/page';
import {
  PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER,
  PROJECT_PAGES_APPLY_DESIGN_TO_PAGE,
  PROJECT_PAGES_APPLY_PHOTO_TO_LAYER,
  PROJECT_PAGES_APPLY_TEMPLATE,
  PROJECT_PAGES_REMOVE_IMAGE,
  PROJECT_PAGES_APPLY_PAGE_COUNT,
  SET_LAYER_DATA,
  SET_LAYER_REGION_DATA,
  SET_LAYER_REGION_DATA_ALL,
  PROJECT_PAGES_REMOVE_USERPHOTOS,
  SET_IMAGE_META_TO_LAYER,
  PROJECT_PAGES_REMOVE_PAGES,
  PROJECT_PAGES_INSERT_PAGES,
  PROJECT_PAGES_ORDER,
  SET_LAYER_DIMS,
  PROJECT_PAGES_APPLY_PHOTO_MOD_TO_LAYER,
  SET_DATA_ON_LAYERS,
  SET_LAYER_IMAGE,
} from './constants';
import { ALBUM_USER_DEFAULT_ALBUM } from '../../userAlbums/constants';
import {
  UI_INSTAGRAM_ALBUM,
  UI_PAGE_SELECTION_SIDE_LEFT,
} from '../../ui/constants';
import { UI_DROPBOX_ALBUM } from '../../dropbox/constants';
import { addPhoto, uploadPhoto } from '../../userPhotos/actions';
import {
  sendLowResolutionNotification,
  sendUploadErrorsNotification,
  dismissLowResolutionNotification,
  sendAnonymousUserNotification,
} from '../../notifications/actions';
import {
  setCurrentAlbum,
  setCurrentPageAction,
  setPartialSelectionAction,
  unsetPartialSelectionAction,
  updatePartialSelection,
  setUserInputData, setAppliedToAllToggle,
} from '../../ui/actions';
import { getFileDimensions, getPhotoFromLayer, getUserPhotoIdFromLayer, getPhotoFromLayerPath, getPhotoFromId } from '../../../helpers/images';
import { generateInitCropData } from '../../../helpers/crop';
import {
  setChildProductData,
  productUpdatePageCount,
  updateAttribute,
  updateAttributeAction,
} from '../../product/actions';
import photo, {
  LocalPhoto,
  type Photo,
  PhotoModifications,
} from '../../../types/photo';
import {
  filterAllLayers,
  croppedPhotoIsHighRes,
  designAttributeNameByCategory,
  setContentOnLayer,
  isEditableTextLayer,
  editableTextLayers,
} from '../../../helpers/layers';
import { optionGet, prop } from '../../../helpers/functions';
import { pagesAttrRange } from '../../../helpers/attributes';
import { getLayerFromPage, projectPagesSelector, currentPageLayersWithColorSyncSelector } from './selectors';
import {
  type Template,
  type Design,
  type Layer,
  type Category,
} from '../../../types/templates';
import {
  stripHiddenPages,
  currentPageAfterRemoved,
  newLastPage,
  getPageCountMinusCover,
  pageHasCompositeDesign,
  createCompositeDesign,
  designIsPartial,
  getInternalPageCount,
} from '../../../helpers/pages';
import {
  productShouldEnableHalfPageSelectionSelector,
  productCategorySelector,
} from '../../product/selectors';
import {
  determineLeftDesign,
  determineRighttDesign,
} from '../../../helpers/templates';
import { shouldAddMatPrefix } from '../../../helpers/product';
import { withId, invertArrayToObject } from '../../../helpers/arrays';
import { addAlbumPhotoToProject } from '../../userAlbums/actions';
import {
  sendAnalyticsForUserRemovedPhoto,
  sendAnalyticsForLayoutChange,
  sendAnalyticsForRemovedPage,
  sendAnalyticsForAddedPage,
  itlyImageEditedEvent,
  itlyImageAdded,
} from '../../analytics/actions';
import match from '../../../helpers/match';
import { appliedToAllSelector, s3DirectUploadSelector } from '../../ui/selectors';
import uuid4 from 'uuid/v4';
import { buildTargetedActionTypeFromParts } from '../../v2/galleries/actions';
import * as actionTypes from '../../../store/v2/galleries/actionTypes';

export const setLayerPhoto = (p: Photo, pageId: string, layerId: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // Dismiss notifications concerning any existing (to-be-replaced) photo.
  getPhotoFromLayerPath(getState(), pageId, layerId).map((oldP) => {
    const id = photo.getId(oldP);
    // Dismiss any low resolution notification for the *specific usage* of this photo.
    dispatch(dismissLowResolutionNotification(id, pageId, layerId));

    return null;
  });

  dispatch({
    type: PROJECT_PAGES_APPLY_PHOTO_TO_LAYER,
    payload: {
      photoModifications: {},
      userPhotoId: photo.getId(p),
      pageId,
      layerId,
      p,
    },
  });
};

export const removeUserPhotos = (layerPaths: Array<string>) => ({
  type: PROJECT_PAGES_REMOVE_USERPHOTOS,
  payload: {
    layerPaths,
  },
});

export const setLayerDataAction = (pageId: string, layerId: string, data: Object) => ({
  type: SET_LAYER_DATA,
  payload: {
    pageId,
    layerId,
    data,
  },
});

const setLayerImage = (pageId: string, layerId: string, image: string) => ({
  type: SET_LAYER_IMAGE,
  payload: {
    pageId,
    layerId,
    image,
  },
});

const setDataOnLayers = (layers: any) => ({
  type: SET_DATA_ON_LAYERS,
  payload: {
    layers,
  },
});

export const saveUserInputDataToLayers = (userInputData: any[]) => (
  dispatch: Function,
  getState: Function
) => {
  // eslint-disable-next-line
  const currentUserInputData = optionGet('ui.userInputData')(
    getState()
  ).getOrElseValue([]);
  const pages = projectPagesSelector(getState());

  const invertedUserData = invertArrayToObject(userInputData);
  const layers = pages.reduce((autoFillLayers, page) => {
    const textLayers = editableTextLayers(page.layers);
    const autofillableLayers = textLayers.filter(x => x.autofill);
    for (let i = 0; i < autofillableLayers.length; i += 1) {
      const element = autofillableLayers[i];
      if (invertedUserData && invertedUserData[element.autofill.id].length) {
        autoFillLayers.push([
          page.id,
          element,
          page.surface,
          invertedUserData[element.autofill.id][0],
        ]);
        // eslint-disable-next-line no-unused-vars
        const [first, ...theRest] = invertedUserData[element.autofill.id];
        invertedUserData[element.autofill.id] = theRest;
      }
    }
    return autoFillLayers;
  }, []);

  Promise.all(
    layers.map(x => setContentOnLayer(...x))
  ).then((x) => {
    // Update all layers with new data
    dispatch(setDataOnLayers(x));
  });
  dispatch(setUserInputData(userInputData));
};

// Given an image url and a hex value, replace `white` or `black` with the opposite
const replaceColor = (image, color) => match(
  '#ffffff', () => image.replace('black', 'white'),
  '#000000', () => image.replace('white', 'black'),
  match.default, () => image,
)(color);

export const setLayerData = (
  pageId: string,
  layerId: string,
  data: Object,
  sendAnalytics: boolean = false
) => (dispatch: Function, getState: () => any) => {
  if (sendAnalytics) {
    dispatch(itlyImageEditedEvent());
  }
  const layersWithSyncColor = currentPageLayersWithColorSyncSelector(pageId)(getState()).filter(x => x.id !== layerId);
  const hasLayersToSyncTo = layersWithSyncColor.length;
  const layer = getLayerFromPage(pageId)(layerId)(getState());
  if (hasLayersToSyncTo && layer.syncColor) {
    // eslint-disable-next-line
    layersWithSyncColor.map(x => {
      if (!x.image) {
        // eslint-disable-next-line
        return;
      }
      dispatch(setLayerImage(pageId, x.id, replaceColor(x.image, data.style.color)));
    });
  }
  dispatch(setLayerDataAction(pageId, layerId, data));
};

export const autofillTextLayers = () => (
  dispatch: (action: any) => void,
  getState: () => any
) => {
  // @todo Fix this to autofill based on expected content of layer
  const textLayers = projectPagesSelector(getState())
    .map(x =>
      x.layers.filter(isEditableTextLayer).map(y => ({
        pageId: x.id,
        layer: y,
        surface: x.surface,
      }))
    )
    .flatten();

  // returns [[data, pageId, layerId]] where data is the data property of text layer
  Promise.all(
    textLayers.map(x => setContentOnLayer(x.pageId, x.layer, x.surface, 'test!'))
  )
    .then((x) => {
      // Update all layers with new data
      dispatch(setDataOnLayers(x));
    })
    .catch(e => console.log({ e })); // @todo improve error handling
};

export const setLayerRegionDataAll = (
  layerId: string,
  className: string,
  data: Object
) => ({
  type: SET_LAYER_REGION_DATA_ALL,
  payload: {
    layerId,
    className,
    data,
  },
});

export const setLayerRegionData = (
  pageId: string,
  layerId: string,
  className: string,
  data: Object
) => ({
  type: SET_LAYER_REGION_DATA,
  payload: {
    pageId,
    layerId,
    className,
    data,
  },
});

export const applyTemplateToPages = (
  template: ?Template,
  fontsLoaded: boolean,
  attributes: Object,
  userPhotos: Array<Object>,
  category: string
) => ({
  type: PROJECT_PAGES_APPLY_TEMPLATE,
  payload: {
    template,
    fontsLoaded,
    attributes,
    userPhotos,
    category,
  },
});

export const applyPageCount = (
  template: Template,
  desiredPageCount: number,
  category: string,
  attributes: Object,
  fontsLoaded: boolean = false
) => ({
  type: PROJECT_PAGES_APPLY_PAGE_COUNT,
  payload: {
    template,
    desiredPageCount,
    attributes,
    category,
    fontsLoaded,
  },
});

export const saveImageMetaToLayer = (
  imgMeta: Object,
  pageId: string,
  layerId: string
) => ({
  type: SET_IMAGE_META_TO_LAYER,
  payload: {
    imgMeta,
    pageId,
    layerId,
  },
});

/* Given a cropData object, a pageId, and a layerId, checks the cropped resolution of the specified layer's
   Photo, if it exists, and creates or dismisses a low resolution notification, as appropriate. */
export const checkLayerPhotoResolution = (
  cropData: Object,
  pageId: string,
  layerId: string
) => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const page = find(state.project.pages, x => x.id === pageId);
  const layer = find(page.layers, x => layerId === x.id);

  // If there is a photo on the layer, check the resolution.
  getPhotoFromLayer(state.userPhotos)(layer).map((p) => {
    const userPhotoId = photo.getId(p);
    const extractedPhoto = photo.extract(p);
    if (!croppedPhotoIsHighRes(extractedPhoto, cropData, page.surface, layer)) {
      return dispatch(
        sendLowResolutionNotification(userPhotoId, page, layerId)
      );
    }

    return dispatch(
      dismissLowResolutionNotification(userPhotoId, page.id, layerId)
    );
  });
};

export function applyCropDataToLayer(
  cropData: Object,
  layerId: string,
  isInitial: boolean = false,
  pageId: string = '',
  shouldSave: boolean = true
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const finalPageId = pageId || state.ui.currentPage;

    dispatch({
      type: PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER,
      payload: {
        pageId: finalPageId,
        cropData: { ...cropData, layerId },
        layerId,
        isInitial,
      },
      shouldSave,
    });

    return dispatch(checkLayerPhotoResolution(cropData, finalPageId, layerId));
  };
}

/**
 * Applies brightness and profilter modifications to a photo layer
 */
export type ApplyPhotoModAction = {
  type: PROJECT_PAGES_APPLY_PHOTO_MOD_TO_LAYER,
  payload: {
    photoModifications: PhotoModifications,
    layerId: string,
    pageId: string,
  },
};

export const applyPhotoModificationsToLayer = (
  photoModifications: PhotoModifications,
  pageId: string = '',
  layerId: string
): ApplyPhotoModAction => ({
  type: PROJECT_PAGES_APPLY_PHOTO_MOD_TO_LAYER,
  payload: {
    photoModifications,
    layerId,
    pageId,
  },
});

export const applyCropAndModificationsToLayer = (
  pageId,
  layerId,
  cropData,
  modifications,
  isInitial,
  sendAnalytics: boolean = false
) => (dispatch: Function, getState: GetState) => {
  const state = getState();
  const applyToAll = appliedToAllSelector(state);
  const listPages = projectPagesSelector(state);
  if (sendAnalytics) {
    dispatch(itlyImageEditedEvent());
  }
  dispatch(applyPhotoModificationsToLayer(modifications, pageId, layerId));
  dispatch(applyCropDataToLayer(cropData, layerId, isInitial, pageId));
  if (applyToAll) {
    listPages.slice(1, listPages.length).forEach((page) => {
      dispatch(applyPhotoModificationsToLayer(modifications, page.id, layerId));
      dispatch(applyCropDataToLayer(cropData, layerId, isInitial, page.id));
    });
  }
};

/* Re-applies the current template after a page count update, causing any pageCount-restricted surfaces to get updated,
   and have the crops reset on any pages with changed surfaces. */
export const postUpdatePageCount = () => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { template, product, project, ui, userPhotos } = getState();
  const { category, attributes } = product;

  const pageCount = getPageCountMinusCover(category)(
    stripHiddenPages(project.pages).length
  );

  dispatch(productUpdatePageCount(pageCount));

  dispatch(
    applyTemplateToPages(
      template,
      ui.editorFontsLoaded,
      product.attributes,
      userPhotos,
      category
    )
  );

  if (template.needsPagesAttribute) {
    const currentPagesAttr = parseInt(attributes.pages, 10);
    const pagesAttr = pagesAttrRange(pageCount).getOrElseValue(0);

    if (currentPagesAttr !== pagesAttr) {
      dispatch(
        updateAttribute({
          pages: pagesAttr.toString(),
        })
      );
    }
  }

  // Make sure the current design is in sync with the UI for partial page selection.
  dispatch(updatePartialSelection());
};

export const addPage = (
  pagesToAdd: number = 2,
  sendAnalytics: boolean = false
) => (dispatch: Dispatch, getState: GetState) => {
  const { template, project, product, ui } = getState();
  const { category, attributes } = product;
  const { editorFontsLoaded } = ui;

  if (sendAnalytics) {
    dispatch(sendAnalyticsForAddedPage({ pagesToAdd }));
  }

  const visiblePages = stripHiddenPages(project.pages);
  const desiredPageCount = visiblePages.length + pagesToAdd;

  dispatch(
    applyPageCount(
      template,
      desiredPageCount,
      category,
      attributes,
      editorFontsLoaded
    )
  );

  dispatch(postUpdatePageCount());
};

export const insertPages = (
  pageId: string,
  pagesToAdd: number = 2,
  sendAnalytics: boolean = false
) => (dispatch: Dispatch, getState: GetState) => {
  const { template, project, product, ui } = getState();
  const { category, attributes } = product;
  const { editorFontsLoaded: fontsLoaded } = ui;

  if (sendAnalytics) {
    dispatch(sendAnalyticsForAddedPage({ pagesToAdd }));
  }

  const visiblePages = stripHiddenPages(project.pages);
  const desiredPageCount = visiblePages.length + pagesToAdd;
  dispatch({
    type: PROJECT_PAGES_INSERT_PAGES,
    payload: {
      template,
      desiredPageCount,
      category,
      attributes,
      pageId,
      pagesToAdd,
      fontsLoaded,
    },
  });

  dispatch(postUpdatePageCount());
};

export const removePages = (
  pageIds: Array<string>,
  sendAnalytics: boolean = false
) => (dispatch: Function, getState: Function) => {
  const { project, product, ui } = getState();
  const { category } = product;

  if (sendAnalytics) {
    dispatch(
      sendAnalyticsForRemovedPage({
        pageIds: JSON.stringify(pageIds),
      })
    );
  }
  if (
    currentPageAfterRemoved(project.pages, pageIds, ui.currentPage).length === 0
  ) {
    const lastPage = newLastPage(project.pages, pageIds).getOrElseValue('');
    dispatch(setCurrentPageAction(lastPage));
  }

  dispatch({
    type: PROJECT_PAGES_REMOVE_PAGES,
    payload: {
      pageIds,
      category,
    },
  });

  dispatch(postUpdatePageCount());
};

export const applyDesignToPageAction = (
  design: Object,
  pageId: string,
  template: Object,
  fontsLoaded: boolean,
  attributes: Object,
  userPhotos: Array<Object>,
  additionalLayers: Array<Layer> = [],
  designId: string,
  category: string = ''
) => ({
  type: PROJECT_PAGES_APPLY_DESIGN_TO_PAGE,
  payload: {
    design,
    pageId,
    template,
    fontsLoaded,
    attributes,
    userPhotos,
    additionalLayers,
    designId,
    category,
  },
});

export const designShouldAffectAttributes = (pageId: String) => (
  category: Category
) => shouldAddMatPrefix(category) || pageId === 'cover';

/* Given Photos oldPhoto and newPhoto, finds all of the layers that are pointed at oldPhoto, and replaces those
   pointers with ones to newPhoto. Both Photos should have the same dimensions. For use after upload completion
   to replace a LocalPhoto or ThirdPartyPhoto with a FlashPhoto. */
export const replacePhotoOnLayers = (oldPhoto: Photo, newPhoto: Photo) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const state = getState();

  const oldId = photo.getId(oldPhoto);

  // Predicate for filterAllLayers
  const layerHasOldId = layer =>
    optionGet('data.userPhotoId')(layer)
      .map(userPhotoId => userPhotoId === oldId)
      .getOrElseValue(false);

  // Get an array of pairs of the type [pageId, layerId] that contain a reference to the old Photo
  const occupiedPageLayerPairs = filterAllLayers(layerHasOldId)(
    state.project.pages
  );

  occupiedPageLayerPairs.forEach(([pageId, layerId]) => {
    dispatch(setLayerPhoto(newPhoto, pageId, layerId));

    const layer = getLayerFromPage(pageId)(layerId)(state);

    // Get the layer's cropData, and check the resolution.
    optionGet('data.cropData')(layer).map(cropData =>
      dispatch(checkLayerPhotoResolution(cropData, pageId, layerId))
    );
  });
};

export const applyPhotoToLayer = (
  p: Photo,
  pageId: string,
  layerId: string
) => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const page = state.project.pages.find((x) => x.id === pageId);
  const layer = page.layers.find((y) => y.id === layerId);

  const surface = page.surface;

  const extractedPhoto = photo.extract(p)
  const cropData = generateInitCropData(extractedPhoto, layer, surface);

  await dispatch(setLayerPhoto(p, pageId, layerId));

  return dispatch(applyCropDataToLayer(cropData, layerId, true, pageId));
};

// Applies the given design to a page by adding the design's specified layers to the page's `layers` array.
export const applyDesignToPage = (
  designToApply: Design,
  pageId: string,
  sendAnalytics: boolean = false
) => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const { template, ui, product, userPhotos, project } = state;
  const { designs, layers } = template;
  const productCategory = productCategorySelector(getState());
  const listPages = projectPagesSelector(state);
  const applyToAll = appliedToAllSelector(getState());

  if (sendAnalytics) {
    dispatch(
      sendAnalyticsForLayoutChange({
        layout: prop('pretty_name')(designToApply).getOrElseValue(''),
        pageId,
      })
    );
  }
  if (template.needsDesignAttribute) {
    // Check if we are updating the design for the first page
    // This is needed on products that required the design attribute
    const targetPageIsFirst: Option<Page> = index(0)(project.pages).chain(
      option.fromPredicate(x => x.id === pageId)
    );

    if (isSome(targetPageIsFirst)) {
      // Only update the attribute if it is indeed the first page
      dispatch(
        updateAttributeAction({
          design: designAttributeNameByCategory(product.category)(
            designToApply
          ),
        })
      );
    }
  }

  // If the the design to apply is partial, handle it differently from a full design.
  if (designIsPartial(designToApply)) {
    const page: Page = withId(pageId)(project.pages);

    // If the page has a composite design, get the array of partial designs that comprise that design.
    const [currentLeft, currentRight] = option
      .fromPredicate(pageHasCompositeDesign)(page)
      .chain(optionGet('surface.design.partialDesignIds'))
      .map((partialDesignIds: Array<string>) =>
        partialDesignIds.map(id => withId(id)(designs))
      )
      .getOrElseValue([]);

    const [leftDesign, rightDesign] = option
      .fromNullable(ui.partialSelection)
      .map(selectedSide => [
        determineLeftDesign(currentLeft)(designToApply)(selectedSide),
        determineRighttDesign(currentRight)(designToApply)(selectedSide),
      ])
      .getOrElse(() => {
        dispatch(setPartialSelectionAction(UI_PAGE_SELECTION_SIDE_LEFT));
        return [designToApply, designToApply];
      });

    const [compositeDesign, additionalLayers] = createCompositeDesign(
      layers,
      page.surface
    )(leftDesign)(rightDesign);

    dispatch(
      applyDesignToPageAction(
        compositeDesign,
        pageId,
        template,
        ui.editorFontsLoaded,
        product.attributes,
        userPhotos,
        additionalLayers,
        designAttributeNameByCategory(productCategory)(compositeDesign),
        productCategory
      )
    );
  } else {
    // Unset partial page selection since the design is a full-width
    if (productShouldEnableHalfPageSelectionSelector(state)) {
      dispatch(unsetPartialSelectionAction());
    }

    dispatch(
      applyDesignToPageAction(
        designToApply,
        pageId,
        template,
        ui.editorFontsLoaded,
        product.attributes,
        userPhotos,
        [],
        designAttributeNameByCategory(productCategory)(designToApply),
        productCategory
      )
    );
  }

  if (applyToAll) {
    const layerId = state.project.pages[0].layers[0].id;
    const firstPage = listPages[0];
    const firstPageLayer = firstPage.layers[0];
    listPages.slice(1, listPages.length).forEach((page) => {
      dispatch(
        applyDesignToPageAction(
          designToApply,
          page.id,
          template,
          ui.editorFontsLoaded,
          product.attributes,
          userPhotos,
          [],
          designAttributeNameByCategory(productCategory)(designToApply),
          productCategory
        )
      );
      dispatch(setLayerData(page.id, layerId, firstPageLayer.data));
    });
  }

  const { product: updatedProduct } = getState();
  dispatch(setChildProductData(updatedProduct, updatedProduct.attributes));
};

export function addImageAndApplyToLayer(
  file: Object,
  layerId: string,
  pageId: string,
  sendAnalytics: boolean = false,
  analyticsSource: string = ''
) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const s3DirectUpload = s3DirectUploadSelector(state);

    const userLoggedIn = !!state.user.authorized;
    const { currentAlbum } = state.ui;

    if (userLoggedIn && !currentAlbum) {
      dispatch(setCurrentAlbum(ALBUM_USER_DEFAULT_ALBUM));
    }

    if (sendAnalytics) {
      dispatch(itlyImageAdded(analyticsSource));
    }

    // Retrieve dimensions (width and height) using the file object.
    const dimensions = await getFileDimensions(file);

    // When uploading directly to S3, the client is responsible for generating
    // the mediaId as this will be passed along to the persistMetadata lambda
    const generatedMediaId = uuid4();

    // Create a LocalPhoto.
    const p = LocalPhoto({
      file,
      dimensions,
      ...(s3DirectUpload ? { mediaId: generatedMediaId } : {}),
    });

    // Save the LocalPhoto to state.
    dispatch(addPhoto(p));

    // Apply the LocalPhoto to the given layer.
    dispatch(applyPhotoToLayer(p, pageId, layerId));

    if (userLoggedIn) {
      // TODO: FUTURE - this shouldn't be hard-coded here.
      // Make sure we avoid adding to all third party albums **
      let disallowed = [UI_INSTAGRAM_ALBUM, UI_DROPBOX_ALBUM];
      getState().facebook.facebookAlbums.forEach((album) => {
        disallowed = disallowed.concat(album.albumId);
      });

      const albumToAddTo = disallowed.includes(currentAlbum)
        ? 'My Photos'
        : currentAlbum;

      // Upload the photo, if possible.
      await dispatch(uploadPhoto(p, albumToAddTo, sendAnalytics));

      if (
        getState().addPhotos.uploadImageErrors &&
        getState().addPhotos.uploadImageErrors.length > 0
      ) {
        dispatch(sendUploadErrorsNotification());
      }
    }
  };
}

// Dispatches an action that removes data.userPhotoId from the layer at the given pageId and layerId.
export const removePhotoFromLayer = (
  pageId: string,
  layerId: string,
  sendAnalytics: boolean = true
) => (dispatch: Dispatch, getState: GetState) => {
  if (sendAnalytics) {
    dispatch(sendAnalyticsForUserRemovedPhoto());
  }
  const state = getState();
  // Retrieve page index and layer index so we can update the appropriate page and layer
  const page = find(state.project.pages, aPage => aPage.id === pageId);
  const layer = find(page.layers, aLayer => layerId === aLayer.id);
  dispatch({
    type: PROJECT_PAGES_REMOVE_IMAGE,
    payload: {
      pageId,
      layerId,
    },
  });
  const applyAll = state.ui.appliedToAllToggle;
  if (applyAll) {
    const allPages = projectPagesSelector(state);
    allPages.slice(1, allPages.length).forEach((pages) => {
      dispatch({
        type: PROJECT_PAGES_REMOVE_IMAGE,
        payload: {
          pageId: pages.id,
          layerId: pages.layers[0].id,
        },
      });
    });
    dispatch(setAppliedToAllToggle(false));
  }

  const userPhotoId = getUserPhotoIdFromLayer(layer).getOrElseValue('');

  dispatch(dismissLowResolutionNotification(userPhotoId, pageId, layerId));
};

export const movePhotoToNewLayer = (
  albumPhoto,
  layerId,
  pageId,
  sourcePageId,
  sourceLayerId,
  sendAnalytics: boolean = false
) => async (dispatch: Function, getState: Function) => {
  if (sourcePageId && sourceLayerId) {
    dispatch(removePhotoFromLayer(sourcePageId, sourceLayerId));
    // @todo dispatch a "moved image" event here
  } else if (sendAnalytics) {
    // Only send if layer was empty, i.e. user added not replaced
    if (
      prop('data')(getLayerFromPage(pageId)(layerId)(getState()))
        .chain(prop('userPhotoId'))
        .map(() => false)
        .getOrElseValue(true)
    ) {
      dispatch(itlyImageAdded('layer-drag'));
    }
    // @todo Add else case with replace event here
  }
  return getPhotoFromId(getState().userPhotos)(albumPhoto.userPhotoId)
    .map(p => dispatch(applyPhotoToLayer(p, pageId, layerId)))
    .getOrElse(() => {
      if (!albumPhoto.autoOriented) {
        const imgId = albumPhoto.id;
        let actionType;
        if (albumPhoto.source === 'local') {
          actionType = buildTargetedActionTypeFromParts(actionTypes.AUTO_ORIENT_IMAGE_IN_GALLERY, imgId, pageId, layerId);
        } else {
          actionType = buildTargetedActionTypeFromParts(actionTypes.UPLOAD_IMAGES_TO_GALLERY, imgId, pageId, layerId);
        }
        dispatch({ type: actionType });
      }
      dispatch(addAlbumPhotoToProject(albumPhoto, layerId, pageId));
    });
};

export const validateResolution = () => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const state = getState();
  const { pages } = state.project;

  pages.forEach((page) => {
    const { layers } = page;

    const userPhotoLayers = layers.filter(layer => layer.type === 'user_photo');

    // If the layer has cropData, check the resolution.
    userPhotoLayers.forEach((photoLayer) => {
      optionGet('data.cropData')(photoLayer).map(cropData =>
        dispatch(checkLayerPhotoResolution(cropData, page.id, photoLayer.id))
      );
    });
  });
};

export const orderPagesAction = (
  category: string,
  pages: Array<Array<Page>>,
  { oldIndex, newIndex }: { newIndex: number, oldIndex: number }
) => ({
  type: PROJECT_PAGES_ORDER,
  payload: {
    newIndex,
    category,
    pageIds: pages[oldIndex].map((page: Page): string => page.id),
  },
});

export const orderPages = (
  category: string,
  pages: Array<Array<Page>>,
  indices: { newIndex: number, oldIndex: number }
) => (dispatch: Dispatch) => {
  const pageCount = getInternalPageCount(pages);
  if (indices.newIndex > pageCount && indices.newIndex > indices.oldIndex) {
    return;
  }
  dispatch(orderPagesAction(category, pages, indices));
  // Make sure the current design is in sync with the UI for partial page selection.
  dispatch(updatePartialSelection());
};

export const setLayerPosition = (
  pageId: string,
  layerId: string,
  x: number,
  y: number
) => ({
  type: SET_LAYER_DIMS,
  payload: {
    pageId,
    layerId,
    dims: {
      x,
      y,
    },
  },
});

export const setLayerSize = (
  pageId: string,
  layerId: string,
  width: number,
  height: number
) => ({
  type: SET_LAYER_DIMS,
  payload: {
    pageId,
    layerId,
    dims: {
      width,
      height,
    },
  },
});

export const setLayerPositionAndSize = (
  pageId: string,
  layerId: string,
  x: number,
  y: number,
  width: number,
  height: number
) => ({
  type: SET_LAYER_DIMS,
  payload: {
    pageId,
    layerId,
    dims: {
      width,
      height,
      x,
      y,
    },
  },
});
